.login-page .container {
  margin: 0 auto 100px;
  max-width: 460px;
  position: relative;
  width: 100%;
}
.login-page .pen-title {
  letter-spacing: 2px;
  padding: 40px 0;
  text-align: center;
}
.login-page .card {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
  box-sizing: border-box;
  padding: 45px 0 40px;
  position: relative;
  transition: .3s ease;
}
.login-page .lable{
  margin: 0 60px 10px;
  position: relative;
}
.login-page .card .input-container {
  margin: 0 60px 50px;
  position: relative;
}
.login-page .card .input-container input {
  background: none;
  border: 0;
  color: #212121;
  font-size: 20px;
  font-weight: 400;
  height: 45px;
  outline: none;
  position: relative;
  width: 100%;
  z-index: 1;
}
/* .login-page .card .input-container label {
  color: #757575;
  font-size: 15px;
  font-weight: 300;
  left: 0;
  line-height: 60px;
  position: absolute;
  top: 0;
  transition: .2s ease;
} */
.login-page .card .input-container .bar {
  background: #757575;
  bottom: 0;
  height: 1px;
  left: 0;
  width: 100%;
}
.login-page .card .title {
  border-left: 5px solid #ed1c24;
  color: #ed1c24;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 35px;
  padding: 10px 0 10px 50px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
}
.login-page .card .button-container {
  margin: 0 60px;
}
.login-page .card .button-container button {
  background: #ed1c24;
  border: 2px solid #ed1c24;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  outline: 0;
  overflow: hidden;
  padding: 7px 0;
  position: relative;
  text-transform: uppercase;
  transition: .3s ease;
  width: 90px;
}
